import axios from "axios";
import { FORM_API_BASE_URL } from "../constants";

// Form Element Type Controller
export const fetchFormElementTypes = (config) => {
  return axios.get(`${FORM_API_BASE_URL}/api/v1/form-element-type/all`, config);
};

export const fetchFormElementTypeById = (id, config) => {
  return axios.get(
    `${FORM_API_BASE_URL}/api/v1/form-element-type/id/${id}`,
    config
  );
};

export const createFormElementType = (data, config) => {
  return axios.post(
    `${FORM_API_BASE_URL}/api/v1/form-element-type/create`,
    data,
    config
  );
};

export const updateFormElementType = (data, config) => {
  return axios.put(
    `${FORM_API_BASE_URL}/api/v1/form-element-type/update`,
    data,
    config
  );
};

export const deleteFormElementTypeById = (id, config) => {
  return axios.delete(
    `${FORM_API_BASE_URL}/api/v1/form-element-type/delete/id/${id}`,
    config
  );
};

// Form Element Controller
export const fetchFormElementById = (id, config) => {
  return axios.get(`${FORM_API_BASE_URL}/api/v1/form-element/id/${id}`, config);
};

export const fetchFormElementWithAPIResources = (id, config) => {
  return axios.get(
    `${FORM_API_BASE_URL}/api/v1/form-element/with-resource/id/${id}`,
    config
  );
};

export const createFormElement = (data, config) => {
  return axios.post(
    `${FORM_API_BASE_URL}/api/v1/form-element/create`,
    data,
    config
  );
};

export const updateFormElement = (data, config) => {
  return axios.put(
    `${FORM_API_BASE_URL}/api/v1/form-element/update`,
    data,
    config
  );
};

export const deleteFormElement = (id) => {
  return axios.delete(
    `${FORM_API_BASE_URL}/api/v1/form-element/delete/id/${id}`
  );
};

// Form Controller
export const fetchForms = (config) => {
  return axios.get(`${FORM_API_BASE_URL}/api/v1/forms/all`, config);
};

export const fetchFormById = (id, config) => {
  return axios.get(`${FORM_API_BASE_URL}/api/v1/forms/id/${id}`, config);
};

export const createForm = (data, config) => {
  return axios.post(`${FORM_API_BASE_URL}/api/v1/forms/create`, data, config);
};

export const updateForm = (data, config) => {
  return axios.put(`${FORM_API_BASE_URL}/api/v1/forms/update`, data, config);
};

export const deleteFormById = (id, config) => {
  return axios.delete(
    `${FORM_API_BASE_URL}/api/v1/forms/delete/id/${id}`,
    config
  );
};

// API Protocol Controller
export const fetchAPIProtocols = (config) => {
  return axios.get(`${FORM_API_BASE_URL}/api/v1/api-protocol/all`, config);
};

export const fetchAPIProtocolsByFormId = (id, config) => {
  return axios.get(
    `${FORM_API_BASE_URL}/api/v1/api-protocol/formId/${id}/all`,
    config
  );
};

export const createAPIProtocol = (data, config) => {
  return axios.post(
    `${FORM_API_BASE_URL}/api/v1/api-protocol/create`,
    data,
    config
  );
};

export const updateAPIProtocol = (data, config) => {
  return axios.put(
    `${FORM_API_BASE_URL}/api/v1/api-protocol/update`,
    data,
    config
  );
};

export const deleteAPIProtocolById = (id, config) => {
  return axios.delete(
    `${FORM_API_BASE_URL}/api/v1/api-protocol/delete/id/${id}`,
    config
  );
};

// API Resource Controller
export const createAPIResource = (data, config) => {
  return axios.post(
    `${FORM_API_BASE_URL}/api/v1/api-resource/create`,
    data,
    config
  );
};

export const updateAPIResource = (data, config) => {
  return axios.put(
    `${FORM_API_BASE_URL}/api/v1/api-resource/update`,
    data,
    config
  );
};

export const deleteAPIResource = (id, config) => {
  return axios.delete(
    `${FORM_API_BASE_URL}/api/v1/api-resource/delete/id/${id}`,
    config
  );
};

export const fetchAPIResourceByElementId = (id, config) => {
  return axios.get(
    `${FORM_API_BASE_URL}/api/v1/form-element-api-data/all/formElementId/${id}`,
    config
  );
};

// API Data Controller
export const createElementAPIData = (data, config) => {
  return axios.post(
    `${FORM_API_BASE_URL}/api/v1/form-element-api-data/create`,
    data,
    config
  );
};

//condition controller
export const createCondition = (data) => {
  return axios.post(`${FORM_API_BASE_URL}/api/conditions/create`, data);
};

export const updateCondition = (data) => {
  return axios.put(`${FORM_API_BASE_URL}/api/conditions/update`, data);
};

export const deleteConditionById = (id) => {
  return axios.delete(`${FORM_API_BASE_URL}/api/conditions/delete/id/${id}`);
};
