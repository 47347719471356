import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import NodeActionButton from "./NodeActionButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Badge } from "@mui/material";

const ElementLabel = ({
  elem,
  onElementDelete,
  onElementEdit,
  onElementConfig,
}) => {
  const {
    question,
    formElementType: { name },
    required,
  } = elem;
  const navigate = useNavigate();
  const totalAPIResource = elem.formElementApiDataSet.filter(
    (i) => !i.deleted
  ).length;

  return (
    <div style={{ fontSize: 8 }}>
      {/* <NodeActionButton
        variant="config"
        onClick={() => onElementConfig(elem.id)}
      >
        <SettingsIcon fontSize="inherit" />
      </NodeActionButton> */}

      {/*<Badge badgeContent={4}>*/}
      {/*    <NodeActionButton*/}
      {/*        variant="view"*/}
      {/*        onClick={() => navigate(`/element/api-resource/${elem.id}`)}*/}
      {/*    >*/}
      {/*        <VisibilityOutlinedIcon fontSize="inherit"/>*/}
      {/*    </NodeActionButton>*/}
      {/*</Badge>*/}
      {/* <NodeActionButton
        variant="view"
        onClick={() => navigate(`/element/api-resource/${elem.id}`)}
      >
        <VisibilityOutlinedIcon fontSize="inherit" />
        <Badge
          badgeContent={totalAPIResource}
          color="info"
          sx={{
            "& .MuiBadge-standard": {
              minWidth: "8px",
              height: "8px",
              fontSize: "5px",
              padding: 0,
              top: "-8px",
              right: " -1px",
            },
          }}
        />
      </NodeActionButton> */}

      <NodeActionButton variant="edit" onClick={() => onElementEdit(elem.id)}>
        <EditIcon fontSize="inherit" color="primary" />
      </NodeActionButton>

      {/* <NodeActionButton
        variant="delete"
        onClick={() => onElementDelete(elem.id)}
      >
        <DeleteIcon fontSize="inherit" />
      </NodeActionButton> */}
      {elem.section && elem.section.name && (
        <p style={nodeSubTextStyle}>
          <strong>Section: </strong>
          {elem.section.name}
        </p>
      )}
      <p style={nodeTextStyle}>{question}</p>
      <p style={nodeSubTextStyle}>
        <strong>Type: </strong>
        {name}
      </p>
      <p style={nodeSubTextStyle}>
        <strong>Required: </strong>
        {required ? "Yes" : "No"}
      </p>
    </div>
  );
};

const nodeTextStyle = {
  margin: 0,
};

const nodeSubTextStyle = {
  ...nodeTextStyle,
  fontSize: 7,
};

ElementLabel.propTypes = {
  elem: PropTypes.object.isRequired,
  onElementEdit: PropTypes.func.isRequired,
  onElementDelete: PropTypes.func.isRequired,
  onElementConfig: PropTypes.func.isRequired,
};

export default ElementLabel;
