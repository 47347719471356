import Select from "@mui/material/Select";
import { Box, spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components/macro";
import Loader from "../../components/ui/Loader";
import MenuItem from "@mui/material/MenuItem";
import { useConfirm } from "material-ui-confirm";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Link,
  Button,
  debounce,
  TextField,
  Typography,
  IconButton,
  Grid as MuiGrid,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
} from "@mui/material";
import {
  createSection,
  deleteSectionById,
  fetchAllSections,
  fetchSectionById,
  updateSection,
} from "../../api/section";
import SectionForm from "../../components/form-builder/SectionForm";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const SectionList = () => {
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(true);
  const [formDialog, setFormDialog] = useState(false);
  const [section, setSection] = useState(null);
  const [sections, setSections] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [parentSections, setParentSections] = useState([]);
  const [selectedParentSections, setSelectedParentSections] = useState([]);
  const [inputValueParentSection, setInputValueParentSection] = useState("");

  const onFormDialogOpen = () => setFormDialog(true);

  const onFormDialogClose = () => {
    setFormDialog(false);
    setSection(null);
    setSelectedParentSections({});
    setInputValueParentSection("");
  };

  const handleParentChange = (event, newValue) => {
    if (newValue) {
      setSelectedParentSections(newValue);
    } else {
      setSelectedParentSections([]);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValueParentSection(newInputValue);
  };
  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const getSelectedSection = (sectionId) => {
    let sectionIndex = parentSections.findIndex((sec) => sec.id == sectionId);
    if (sectionIndex !== -1) {
      return parentSections[sectionIndex];
    } else {
      return {};
    }
  };

  const onEditButtonHandler = (id) => {
    fetchSectionById(id)
      .then((res) => {
        if (res.data.success) {
          setSection(res.data.payload);
          if (
            res.data.payload.parentSectionDetails &&
            res.data.payload.parentSectionDetails.id
          ) {
            setSelectedParentSections(
              getSelectedSection(res.data.payload.parentSectionDetails.id)
            );
          }
          setFormDialog(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const getAllParentSections = async () => {
    const config = {
      params: {
        asc_or_desc: "ASCENDING",
        sort_by: "name",
        parents: true,
        page_size: 100,
        name:
          inputValueParentSection.length > 0 ? inputValueParentSection : null,
      },
    };
    fetchAllSections(config)
      .then((res) => {
        const sectionResp = res.data.payload;
        setParentSections(sectionResp.content);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onDeleteButtonHandler = (id) => {
    confirm({
      title: "Are you sure to delete?",
      confirmationText: "Delete",
      dialogProps: { maxWidth: "xs" },
    }).then(() => {
      deleteSectionById(id)
        .then((res) => {
          if (res.data.success) {
            const filteredSections = sections.filter(
              (form) => form.id !== res.data.payload.id
            );
            setSections(filteredSections);
          }
        })
        .catch((err) => console.log(err));
    });
  };

  const onFormSubmitHandler = (values, { setSubmitting }) => {
    const data = {
      name: values.name,
      shortDescription: values.shortDescription,
      precedence: values.precedence,
      parentSection: selectedParentSections?.id || null,
      active: values.active,
    };

    if (!section) {
      createSection(data)
        .then((res) => {
          if (res.data.success) {
            getsections();
            setInputValueParentSection("");
            setSelectedParentSections({});
            // setSections((prevState) => {
            //   return [...prevState, res.data.payload];
            // });
            setSubmitting(false);
            setFormDialog(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
          setFormDialog(false);
        });
    } else {
      updateSection({ id: section.id, ...data })
        .then((res) => {
          if (res.data.success) {
            getsections();
            setSelectedParentSections({});
            setInputValueParentSection("");
            // const filteredSections = sections.filter(
            //   (sec) => sec.id !== res.data.payload.id
            // );
            // filteredSections.push(res.data.payload);
            // setSections(filteredSections);
            setSection(null);
            setSubmitting(false);
            setFormDialog(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSection(null);
          setSubmitting(false);
          setFormDialog(false);
        });
    }
  };

  // Sorting & Searching Filter
  const [sortBy, setSortBy] = useState("creationDate");
  const [ascOrDesc, setAscOrDesc] = useState("ASCENDING");
  const [searchQuery, setSearchQuery] = useState("");

  const onSortChange = (event) => {
    const value = event.target.value;
    const sortValue = value.split("_")[0];
    const ascDescValue = value.split("_")[1];
    setSortBy(sortValue);
    setAscOrDesc(ascDescValue);
  };

  const onSearch = debounce((event) => {
    const value = event.target.value;
    setSearchQuery(value);
  }, 600);

  const getsections = () => {
    const config = {
      params: {
        asc_or_desc: ascOrDesc,
        sort_by: sortBy,
        page_no: pageNo,
        page_size: pageSize,
        name: searchQuery,
      },
    };
    fetchAllSections(config)
      .then((res) => {
        const sectionResp = res.data.payload;
        setTotalItems(sectionResp.totalElements);
        setSections(sectionResp.content);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllParentSections();
  }, [inputValueParentSection]);

  useEffect(() => {
    getsections();
  }, [sortBy, ascOrDesc, searchQuery]);

  return (
    <div>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Sections
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Forms
            </Link>
            <Typography>Sections</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={onFormDialogOpen}
            >
              <Add />
              Create Section
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={5} />

      {isLoading && <Loader />}

      {!isLoading && (
        <div>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              marginBottom: 5,
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item sm={6} lg={3}>
                <FormControl fullWidth mt={6}>
                  <InputLabel id="sortBy-label">Sorted By</InputLabel>
                  <Select
                    id="sortBy"
                    name="sortBy"
                    label="Sorted By"
                    labelId="sortBy-label"
                    onChange={onSortChange}
                    value={sortBy && `${sortBy}_${ascOrDesc}`}
                  >
                    <MenuItem value="name_ASCENDING">Name (A-Z)</MenuItem>
                    <MenuItem value="name_DESCENDING">Name (Z-A)</MenuItem>
                    <MenuItem value="creationDate_ASCENDING">
                      Date - Ascending
                    </MenuItem>
                    <MenuItem value="creationDate_DESCENDING">
                      Date - Descending
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} lg={3}>
                <TextField fullWidth label="Search" onChange={onSearch} />
              </Grid>
            </Grid>
          </Box>
          <Paper>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" padding="normal">
                      Sl. No.
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      Name
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      Parent
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      Precedence
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      Usage in Form Elements
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      Usage in Forms
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      Status
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {sections && sections.length > 0 ? (
                    sections.map((sec, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={sec.id}
                        >
                          <TableCell align="left">
                            {pageNo * pageSize + index + 1}.
                          </TableCell>
                          <TableCell align="left">{sec.name}</TableCell>
                          <TableCell align="left">
                            {sec.parentSectionDetails?.name}
                          </TableCell>

                          <TableCell align="left">{sec.precedence}</TableCell>
                          <TableCell align="left">
                            {sec.usagesInFormElements}
                          </TableCell>
                          <TableCell align="left">
                            {sec.usagesInForms}
                          </TableCell>
                          <TableCell align="left">
                            {sec.active ? "Active" : "Inactive"}
                          </TableCell>
                          <TableCell padding="none" align="left">
                            <Box mr={2}>
                              <IconButton
                                aria-label="details"
                                size="large"
                                onClick={() => onEditButtonHandler(sec.id)}
                              >
                                <Edit color="primary" />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => onDeleteButtonHandler(sec.id)}
                              >
                                <Delete sx={{ color: "#d93232" }} />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7}>No Sections Found!</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={totalItems}
              rowsPerPage={pageSize}
              page={pageNo}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}

      {onFormDialogOpen && (
        <SectionForm
          open={formDialog}
          initialValue={section}
          parentSections={parentSections}
          selectedParentSections={selectedParentSections}
          handleParentChange={handleParentChange}
          handleInputChange={handleInputChange}
          onCloseHandler={onFormDialogClose}
          onFormSubmitHandler={onFormSubmitHandler}
        />
      )}
    </div>
  );
};

export default SectionList;
