import * as Yup from "yup";
import {spacing} from "@mui/system";
import Select from "@mui/material/Select";
import {Close} from "@mui/icons-material";
import styled from "styled-components/macro";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import ModalCloseIcon from "./ModalCloseIcon";
import {Formik, FieldArray, Field} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiFormControl from "@mui/material/FormControl";
import {
    Dialog,
    IconButton,
    FormHelperText,
    Grid as MuiGrid,
    TextField as MuiTextField
} from "@mui/material";

const Grid = styled(MuiGrid)(spacing);
const Button = styled(LoadingButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const FormControl = styled(MuiFormControl)(spacing);

const FieldSet = styled.fieldset`
  ${spacing};
  border-radius: 10px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding-top: 20px;
  padding-bottom: 25px;
`;

const bodyRequestField = {
    field: "",
    fieldType: "",
    required: ""
};

const apiHeader = {
    key: "",
    value: ""
};

const defaultValues = {
    active: "",
    apiUrl: "",
    requestMethod: "",
    headers: [apiHeader],
    formElementApiDataType: "",
    createUpdateBodyRequests: [bodyRequestField]
};

const validationSchema = Yup.object().shape({
    active: Yup.boolean().required("Required"),
    apiUrl: Yup.string().required("Required"),
    requestMethod: Yup.string().required("Required"),
    formElementApiDataType: Yup.string().required("Required"),
    headers: Yup.array(),
    createUpdateBodyRequests: Yup.array()
});

const APIResourceForm = ({initialValues, open, onClose, onSubmit}) => {
    if (initialValues) {
        const {headers, requestBodies} = initialValues;
        const reqHeaders = headers ? Object.entries(headers).map(header => {
            return {
                key: header[0],
                value: header[1],
            }
        }) : defaultValues.headers
        initialValues = {
            ...initialValues,
            headers: reqHeaders.length > 0 ? reqHeaders : defaultValues.headers,
            createUpdateBodyRequests: requestBodies ?? defaultValues.createUpdateBodyRequests,
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
            <ModalCloseIcon onClick={onClose}/>
            <Formik
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                initialValues={initialValues ?? defaultValues}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      isSubmitting
                  }) => (
                    <form noValidate onSubmit={handleSubmit} style={{padding: 30}}>
                        <h2 style={{margin: 0}}>{initialValues ? "Update" : "Configure"} Form Element</h2>
                        <TextField
                            mt={7}
                            fullWidth
                            id="apiUrl"
                            label="API URL"
                            onChange={handleChange}
                            defaultValue={values?.apiUrl}
                            helperText={touched.apiUrl && errors.apiUrl}
                            errors={(Boolean(touched.apiUrl && errors.apiUrl))}
                        />

                        <Grid container spacing={3}>
                            <Grid md={6} item>
                                <FormControl fullWidth mt={6}>
                                    <InputLabel id="requestMethod-label">Request Method</InputLabel>
                                    <Select
                                        id="requestMethod"
                                        name="requestMethod"
                                        label="Request Method"
                                        onChange={handleChange}
                                        value={values.requestMethod}
                                        labelId="requestMethod-label"
                                    >
                                        <MenuItem value="GET">Get</MenuItem>
                                        <MenuItem value="PUT">Put</MenuItem>
                                        <MenuItem value="POST">Post</MenuItem>
                                        <MenuItem value="DELETE">Delete</MenuItem>
                                    </Select>

                                    {(Boolean(touched.requestMethod && errors.requestMethod)) && (
                                        <FormHelperText error={true}>{errors.requestMethod}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid md={6} item>
                                <FormControl fullWidth mt={6}>
                                    <InputLabel id="formElementApiDataType-label">Form Element Type</InputLabel>
                                    <Select
                                        id="formElementApiDataType"
                                        onChange={handleChange}
                                        name="formElementApiDataType"
                                        label="Form Element Type"
                                        value={values.formElementApiDataType}
                                        labelId="formElementApiDataType-label"
                                    >
                                        <MenuItem value="CONDITIONAL_NODE">Conditional</MenuItem>
                                        <MenuItem value="FORM_ELEMENT_API_DATA">API Data</MenuItem>
                                    </Select>

                                    {(Boolean(touched.formElementApiDataType && errors.formElementApiDataType)) && (
                                        <FormHelperText error={true}>{errors.formElementApiDataType}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>

                        <FieldArray
                            name="createUpdateBodyRequests"
                            render={arrayHelpers => (
                                <div>
                                    <FieldSet mt={6}>
                                        <legend>Body Request Fields</legend>
                                        {values.createUpdateBodyRequests.map((bodyRequestField, index) => (
                                            <div key={index} style={{marginTop: index ? 20 : 0}}>
                                                <Grid container spacing={3}>
                                                    <Grid md={4} item>
                                                        <Field name={`createUpdateBodyRequests.${index}.field`}>
                                                            {({field}) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    label="Field Name"
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>

                                                    <Grid md={4} item>
                                                        <Field name={`createUpdateBodyRequests.${index}.fieldType`}>
                                                            {({field}) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    label="Field Type"
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>

                                                    <Grid md={4} item>
                                                        <Field name={`createUpdateBodyRequests.${index}.required`}>
                                                            {({field}) => (
                                                                <FormControl fullWidth>
                                                                    <InputLabel
                                                                        id="required-label">Required</InputLabel>
                                                                    <Select
                                                                        {...field}
                                                                        label="Required"
                                                                        labelId="required-label"
                                                                    >
                                                                        <MenuItem value={true}>Yes</MenuItem>
                                                                        <MenuItem value={false}>No</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                </Grid>
                                                {index ? (
                                                    <Button
                                                        mt={1}
                                                        color="error"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        Remove
                                                    </Button>
                                                ) : null}
                                            </div>
                                        ))}
                                    </FieldSet>
                                    <Button onClick={() => arrayHelpers.push(bodyRequestField)} mt={3}>Add More
                                        Field</Button>
                                </div>
                            )}
                        />

                        <FieldArray
                            name="headers"
                            render={arrayHelpers => (
                                <div>
                                    <FieldSet mt={3}>
                                        <legend>Headers</legend>
                                        {values.headers.map((header, index) => (
                                            <div key={index} style={{marginTop: index ? 20 : 0}}>
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid sm={5} item>
                                                        <Field name={`headers.${index}.key`}>
                                                            {({field}) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    label="Key"
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>

                                                    <Grid sm={5} item>
                                                        <Field name={`headers.${index}.value`}>
                                                            {({field}) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    label="Value"
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>

                                                    {index ? (
                                                        <Grid sm={2} item>
                                                            <IconButton
                                                                color="error"
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                <Close/>
                                                            </IconButton>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </div>
                                        ))}
                                    </FieldSet>
                                    <Button onClick={() => arrayHelpers.push(apiHeader)} mt={3}>Add More Header</Button>
                                </div>
                            )}
                        />

                        <FormControl fullWidth mt={6}>
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                                id="active"
                                name="active"
                                label="Status"
                                labelId="status-label"
                                onChange={handleChange}
                                value={values?.active}
                                error={touched.active && errors.active}
                                helperText={touched.active && errors.active}
                            >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>

                            {(Boolean(touched.active && errors.active)) && (
                                <FormHelperText error={true}>{errors.active}</FormHelperText>
                            )}
                        </FormControl>

                        <Button
                            mt={6}
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            {initialValues ? "Update" : "Configure"}
                        </Button>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default APIResourceForm;
