import Select from "@mui/material/Select";
import { Box, spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components/macro";
import Loader from "../../components/ui/Loader";
import MenuItem from "@mui/material/MenuItem";
import { useConfirm } from "material-ui-confirm";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Add, Delete, Edit } from "@mui/icons-material";
import ElementTypeForm from "../../components/form-builder/ElementTypeForm";
import {
  createFormElementType,
  updateFormElementType,
  fetchFormElementTypes,
  fetchFormElementTypeById,
  deleteFormElementTypeById
} from "../../api/form-builder";
import {
  Link,
  Button,
  debounce,
  TextField,
  Typography,
  IconButton,
  Grid as MuiGrid,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs
} from "@mui/material";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const FormElementType = () => {
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(true);
  const [formDialog, setFormDialog] = useState(false);
  const [formElementType, setFormElementType] = useState(null);
  const [formElementTypes, setFormElementTypes] = useState([]);

  const onFormDialogOpen = () => setFormDialog(true);

  const onFormDialogClose = () => {
    setFormDialog(false);
    setFormElementType(null);
  };

  const onEditButtonHandler = (id) => {
    fetchFormElementTypeById(id)
      .then(res => {
        if (res.data.success) {
          setFormElementType(res.data.payload);
          setFormDialog(true);
        }
      })
      .catch(err => console.log(err));
  };

  const onDeleteButtonHandler = (id) => {
    confirm({
      title: "Are you sure to delete?",
      confirmationText: "Delete",
      dialogProps: { maxWidth: "xs" }
    }).then(() => {
      deleteFormElementTypeById(id)
        .then(res => {
          if (res.data.success) {
            const filteredForms = formElementTypes.filter(form => form.id !== res.data.payload.id);
            setFormElementTypes(filteredForms);
          }
        })
        .catch(err => console.log(err));
    });
  };

  const onFormSubmitHandler = (values, { setSubmitting }) => {
    const data = {
      name: values.name,
      type: values.type,
      active: values.active
    };

    if (!formElementType) {
      createFormElementType(data)
        .then(res => {
          if (res.data.success) {
            setFormElementTypes(prevState => {
              return [
                ...prevState,
                res.data.payload
              ];
            });
            setSubmitting(false);
            setFormDialog(false);
          }
        })
        .catch(error => {
          console.log(error);
          setSubmitting(false);
          setFormDialog(false);
        });
    } else {
      updateFormElementType({ formElementTypeId: formElementType.id, ...data })
        .then(res => {
          if (res.data.success) {
            const filteredForms = formElementTypes.filter(form => form.id !== res.data.payload.id);
            filteredForms.push(res.data.payload);
            setFormElementTypes(filteredForms);
            setFormElementType(null);
            setSubmitting(false);
            setFormDialog(false);
          }
        })
        .catch(error => {
          console.log(error);
          setFormElementType(null);
          setSubmitting(false);
          setFormDialog(false);
        });
    }
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1, sortable: false },
    {
      field: "active",
      headerName: "Status",
      flex: 1,
      sortable: false,
      renderCell: (params) => params.value ? "Active" : "Inactive"
    },
    { field: "type", headerName: "Type/Tag", flex: 1, sortable: false },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => onEditButtonHandler(params.id)}>
          <Edit />
        </IconButton>
      )
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <IconButton color="error" onClick={() => onDeleteButtonHandler(params.id)}>
          <Delete />
        </IconButton>
      )
    }
  ];

  // Sorting & Searching Filter
  const [sortBy, setSortBy] = useState("creationDate");
  const [ascOrDesc, setAscOrDesc] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");

  const onSortChange = (event) => {
    const value = event.target.value;
    const sortValue = value.split("_")[0];
    const ascDescValue = value.split("_")[1];
    setSortBy(sortValue);
    setAscOrDesc(ascDescValue);
  };

  const onSearch = debounce((event) => {
    const value = event.target.value;
    setSearchQuery(value);
  }, 600);

  const getFormElementTypes = () => {
    const config = {
      params: {
        asc_or_desc: ascOrDesc,
        sort_by: sortBy,
        name: searchQuery
      }
    };
    fetchFormElementTypes(config)
      .then(res => {
        const filteredForms = res.data.payload.content.filter(form => !form.deleted);
        setFormElementTypes(filteredForms);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getFormElementTypes();
  }, [sortBy, ascOrDesc, searchQuery]);

  return (
    <div>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Form Element Types
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Forms
            </Link>
            <Typography>Element Types</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button variant="contained" color="primary" onClick={onFormDialogOpen}>
              <Add />
              Create Element Type
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={5} />

      {isLoading && <Loader />}

      {!isLoading && (
        <div>
          <Box sx={{ backgroundColor: "white", padding: "20px", borderRadius: "5px", marginBottom: 5 }}>
            <Grid container justifyContent="space-between">
              <Grid item sm={6} lg={3}>
                <FormControl fullWidth mt={6}>
                  <InputLabel id="sortBy-label">Sorted By</InputLabel>
                  <Select
                    id="sortBy"
                    name="sortBy"
                    label="Sorted By"
                    labelId="sortBy-label"
                    onChange={onSortChange}
                    value={sortBy && `${sortBy}_${ascOrDesc}`}
                  >
                    <MenuItem value="name_asc">Name (A-Z)</MenuItem>
                    <MenuItem value="name_desc">Name (Z-A)</MenuItem>
                    <MenuItem value="creationDate_asc">Date - Ascending</MenuItem>
                    <MenuItem value="creationDate_desc">Date - Descending</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} lg={3}>
                <TextField
                  fullWidth
                  label="Search"
                  onChange={onSearch}
                />
              </Grid>
            </Grid>
          </Box>

          <DataGrid
            pageSize={10}
            rows={formElementTypes}
            columns={columns}
            autoHeight={true}
            disableColumnMenu={true}
            checkboxSelection={true}
            rowsPerPageOptions={[10, 20]}
          />
        </div>
      )}

      {onFormDialogOpen && (
        <ElementTypeForm
          open={formDialog}
          initialValue={formElementType}
          onCloseHandler={onFormDialogClose}
          onFormSubmitHandler={onFormSubmitHandler}
        />
      )}
    </div>
  );
};

export default FormElementType;
