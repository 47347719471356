import * as Yup from "yup";
import {Formik} from "formik";
import {spacing} from "@mui/system";
import Select from "@mui/material/Select";
import styled from "styled-components/macro";
import MenuItem from "@mui/material/MenuItem";
import {useTheme} from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import ModalCloseIcon from "./ModalCloseIcon";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Dialog, FormHelperText, TextField} from "@mui/material";

const defaultValue = {
    name: "",
    tag: "",
    active: "",
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is a required field"),
    tag: Yup.string().matches(/^([a-z])[\da-z_-]+$/g, 'Tag must contain lowercase').required("Tag is a required field"),
    active: Yup.boolean().required("Status is a required field")
});

const Button = styled(LoadingButton)(spacing);

const FormCreateUpdate = ({initialValue, open, onCloseHandler, onFormSubmitHandler}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog
            open={open}
            fullWidth={true}
            fullScreen={fullScreen}
            onClose={onCloseHandler}
        >
            <ModalCloseIcon onClick={onCloseHandler}/>

            <div style={{padding: "30px"}}>
                <h2 style={{marginTop: 0}}>
                    {!initialValue ? "Create New Form" : "Update Form"}
                </h2>
                <Formik
                    onSubmit={onFormSubmitHandler}
                    validationSchema={validationSchema}
                    initialValues={initialValue ?? defaultValue}
                >
                    {({
                          values,
                          handleSubmit,
                          handleChange,
                          isSubmitting,
                          touched,
                          errors
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <TextField
                                id="name"
                                fullWidth
                                label="Name"
                                onChange={handleChange}
                                defaultValue={values?.name}
                                sx={{marginBottom: 7, marginTop: 2}}
                                helperText={touched.name && errors.name}
                                error={Boolean(touched.name && errors.name)}
                            />

                            <TextField
                                id="tag"
                                fullWidth
                                label="Tag"
                                disabled={initialValue}
                                onChange={handleChange}
                                defaultValue={values?.tag}
                                sx={{marginBottom: 7, marginTop: 2}}
                                helperText={touched.tag && errors.tag}
                                error={Boolean(touched.tag && errors.tag)}
                            />

                            <FormControl fullWidth>
                                <InputLabel id="status-label">Status</InputLabel>
                                <Select
                                    id="active"
                                    label="Status"
                                    labelId="status-label"
                                    name="active"
                                    onChange={handleChange}
                                    value={values?.active}
                                    error={Boolean(touched.active && errors.active)}
                                >
                                    <MenuItem value={true}>Active</MenuItem>
                                    <MenuItem value={false}>Inactive</MenuItem>
                                </Select>
                                {Boolean(errors.active && touched.active) && (
                                    <FormHelperText error={true}>{errors.active}</FormHelperText>
                                )}
                            </FormControl>

                            <Button
                                mt={5}
                                type="submit"
                                size="large"
                                variant="contained"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                            >
                                {initialValue ? "Edit" : "Create"} Form
                            </Button>
                        </form>
                    )}
                </Formik>
            </div>
        </Dialog>
    );
};

export default FormCreateUpdate;
