import { positions } from "@mui/system";
import { css } from "styled-components";
import styled from "styled-components/macro";

const NodeActionButtonStyle = styled.button`
  ${positions};
  top: -9px;
  right: 2px;
  z-index:999;
  transition:0.4s;
  position: absolute;
  background-color:white;
  padding: 0;
  border: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 20px;
  cursor: pointer;
  font-size:10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  
  ${props => props.variant === "edit" && css`
      right:20px;
  `}
  
  ${props => props.variant === "config" && css`
      left: 2px;
      right:0;
  `}
  
  ${props => props.variant === "view" && css`
      left: 20px;
      right:0;
  `}
  
  &:hover{
    color:white;
    
    ${props => props.variant === "config" && css`
      background-color: ${props.theme.palette.success.main}
    `}
    
    ${props => props.variant === "edit" && css`
      background-color: ${props.theme.palette.primary.main}
    `}
    
    ${props => props.variant === "delete" && css`
      background-color: ${props.theme.palette.error.main}
    `}
    
    ${props => props.variant === "view" && css`
      background-color: ${props.theme.palette.secondary.main}
    `}
  }
`;


const NodeActionButton = ({ children, ...props }) => {
  return (
    <NodeActionButtonStyle {...props}>
      {children}
    </NodeActionButtonStyle>
  );
};

export default NodeActionButton;
