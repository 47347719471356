import Select from "@mui/material/Select";
import { Box, spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../components/ui/Loader";
import styled from "styled-components/macro";
import MenuItem from "@mui/material/MenuItem";
import { useConfirm } from "material-ui-confirm";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  Add,
  Delete,
  Edit,
  DynamicFormOutlined,
  RemoveRedEye,
  Quiz,
} from "@mui/icons-material";
import FormCreateUpdate from "../../components/form-builder/FormCreateUpdate";
import {
  createForm,
  deleteFormById,
  fetchFormById,
  fetchForms,
  updateForm,
} from "../../api/form-builder";
import {
  Button,
  debounce,
  TextField,
  Typography,
  IconButton,
  Grid as MuiGrid,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Stack,
  Tooltip,
} from "@mui/material";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const FormList = () => {
  const confirm = useConfirm();
  const [forms, setForms] = useState([]);
  const [form, setForm] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formDialog, setFormDialog] = useState(false);

  const onFormDialogOpen = () => setFormDialog(true);

  const onFormDialogClose = () => {
    setFormDialog(false);
    setForm(null);
  };

  const onFormEdit = (id) => {
    fetchFormById(id)
      .then((res) => {
        if (res.data.success) {
          setForm(res.data.payload);
          setFormDialog(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const onFormDelete = (id) => {
    confirm({
      title: "Are you sure to delete?",
      confirmationText: "Delete",
      dialogProps: { maxWidth: "xs" },
    }).then(() => {
      deleteFormById(id)
        .then((res) => {
          if (res.data.success) {
            const filteredForms = forms.filter(
              (form) => form.id !== res.data.payload.id
            );
            setForms(filteredForms);
          }
        })
        .catch((err) => console.log(err));
    });
  };

  const handleFormCreateUpdate = (values, { setSubmitting }) => {
    const data = {
      name: values.name,
      tag: values.tag,
      active: values.active,
    };

    if (!form) {
      createForm(data)
        .then((res) => {
          if (res.data.success) {
            setForms((prevState) => {
              return [...prevState, res.data.payload];
            });
            setSubmitting(false);
            setFormDialog(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
          setFormDialog(false);
        });
    } else {
      updateForm({ formId: form.id, ...data })
        .then((res) => {
          if (res.data.success) {
            const filteredForms = forms.filter(
              (form) => form.id !== res.data.payload.id
            );
            filteredForms.push(res.data.payload);
            setForms(filteredForms);
            setForm(null);
            setSubmitting(false);
            setFormDialog(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
          setFormDialog(false);
        });
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tag",
      headerName: "Tag",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "active",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: (params) => (params.value ? "Active" : "Inactive"),
    },
    // {
    //   field: "build",
    //   headerName: "Build",
    //   flex: 1,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <IconButton
    //       color="success"
    //       component={NavLink}
    //       to={`/build/${params.id}`}
    //     >
    //       <DynamicFormOutlined />
    //     </IconButton>
    //   ),
    // },
    {
      field: "edit",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Stack direction={"row"}>
          <Tooltip title="Questions Flow">
            <IconButton
              color="secondary"
              component={NavLink}
              to={`/build/${params.id}`}
            >
              <Quiz />
              {/* <DynamicFormOutlined /> */}
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton color="primary" onClick={() => onFormEdit(params.id)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   flex: 1,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <IconButton color="error" onClick={() => onFormDelete(params.id)}>
    //       <Delete />
    //     </IconButton>
    //   ),
    // },
  ];

  // Sorting & Searching Filter
  const [sortBy, setSortBy] = useState("creationDate");
  const [ascOrDesc, setAscOrDesc] = useState("desc");
  const [searchQuery, setSearchQuery] = useState("");

  const onSortChange = (event) => {
    const value = event.target.value;
    const sortValue = value.split("_")[0];
    const ascDescValue = value.split("_")[1];
    setSortBy(sortValue);
    setAscOrDesc(ascDescValue);
  };

  const onSearch = debounce((event) => {
    const value = event.target.value;
    setSearchQuery(value);
  }, 600);

  const getAllForms = () => {
    const config = {
      params: {
        asc_or_desc: ascOrDesc,
        sort_by: sortBy,
        name: searchQuery,
      },
    };
    fetchForms(config)
      .then((res) => {
        const filteredForms = res.data.payload.content.filter(
          (form) => !form.deleted
        );
        setForms(filteredForms);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllForms();
  }, [sortBy, ascOrDesc, searchQuery]);

  return (
    <div>
      <Grid
        justifyContent="center"
        alignItems={"center"}
        container
        spacing={10}
        sx={{ mb: 10 }}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Manage MinaWill Forms
          </Typography>
          {/* 
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Forms</Typography>
          </Breadcrumbs> */}
        </Grid>
        {/* <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={onFormDialogOpen}
            >
              <Add />
              Create Form
            </Button>
          </div>
        </Grid> */}
      </Grid>

      {/* <Divider my={5} /> */}

      {isLoading && <Loader />}

      {!isLoading && (
        <div>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              marginBottom: 5,
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item sm={6} lg={3}>
                <FormControl fullWidth mt={6}>
                  <InputLabel id="sortBy-label">Sorted By</InputLabel>
                  <Select
                    id="sortBy"
                    name="sortBy"
                    label="Sorted By"
                    labelId="sortBy-label"
                    onChange={onSortChange}
                    value={`${sortBy}_${ascOrDesc}`}
                  >
                    <MenuItem value="name_asc">Name (A-Z)</MenuItem>
                    <MenuItem value="name_desc">Name (Z-A)</MenuItem>
                    <MenuItem value="creationDate_asc">
                      Date - Ascending
                    </MenuItem>
                    <MenuItem value="creationDate_desc">
                      Date - Descending
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} lg={3}>
                <TextField fullWidth label="Search" onChange={onSearch} />
              </Grid>
            </Grid>
          </Box>

          <DataGrid
            pageSize={10}
            rows={forms}
            columns={columns}
            autoHeight={true}
            sortingOrder={false}
            disableColumnMenu={true}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            disableColumnSelector={true}
            rowsPerPageOptions={[10, 20]}
          />
        </div>
      )}

      {onFormDialogOpen && (
        <FormCreateUpdate
          open={formDialog}
          initialValue={form}
          onCloseHandler={onFormDialogClose}
          onFormSubmitHandler={handleFormCreateUpdate}
        />
      )}
    </div>
  );
};

export default FormList;
