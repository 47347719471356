// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO"
};

// Form API Base URL
export const FORM_API_BASE_URL = process.env.REACT_APP_FORM_API_BASE_URL;
