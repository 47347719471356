import * as Yup from "yup";
import { Formik } from "formik";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import Select from "@mui/material/Select";
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchFormElementTypes } from "../../api/form-builder";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormControl as MuiFormControl,
  FormHelperText,
  TextField as MuiTextField,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
  Autocomplete,
} from "@mui/material";

import { ExpandMore } from "@mui/icons-material";
import ModalCloseIcon from "./ModalCloseIcon";

import Loader from "../ui/Loader";

const Button = styled(LoadingButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const FormControl = styled(MuiFormControl)(spacing);

const defaultValues = {
  active: "",
  required: "",
  question: "",
  placeholder: "",
  formElementType: "",
  formElementIdentifier: "",
  validationType: "",
  lastNode: "",
  sectionElementEnd: "",
  description: "",
};

const validationSchema = Yup.object().shape({
  formElementType: Yup.string().required(),
  formElementIdentifier: Yup.string().required(),
  question: Yup.string().required(),
  placeholder: Yup.string().required(),
  description: Yup.string().notRequired().nullable(),
  required: Yup.boolean().required(),
  active: Yup.boolean().required(),
  validationType: Yup.string().required(),
  lastNode: Yup.boolean().required(),
  sectionElementEnd: Yup.boolean().notRequired().nullable(),
});

const ElementForm = ({
  options,
  onClose,
  onSubmit,
  initialValues,
  childSections,
  selectedChildSections,
  handleChildChange,
  handleInputChange,
  getClientsideValidationProps,
  handleCheckChange,
  currentType,
  handleValidationChange,
  getChildrenValue,
  handleAddOptions,
  handleOptionChange,
  handleRemoveOptions,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [formElementTypes, setFormElementTypes] = useState([]);

  const getFormElementTypes = () => {
    fetchFormElementTypes({
      params: {
        sort_by: "name",
        asc_or_desc: "asc",
      },
    })
      .then((res) => {
        const filteredForms = res.data.payload.content.filter(
          (form) => !form.deleted && form.active
        );

        setFormElementTypes(filteredForms);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFormElementTypes();
  }, []);

  // if (formElementTypes.length === 0) return null;

  return (
    // <Dialog
    //   open={open}
    //   fullWidth={true}
    //   onClose={onClose}
    //   fullScreen={fullScreen}
    // >
    // <Box>
    //   {formElementTypes.length < 1 ? (
    //     <Box mt={50}>
    //       <Loader />
    //     </Box>
    //   ) : (
    <>
      <ModalCloseIcon onClick={onClose} />
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues ?? defaultValues}
      >
        {({
          values,
          isSubmitting,
          handleSubmit,
          handleChange,
          touched,
          errors,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div style={{ padding: 30 }}>
              <h2 style={{ margin: 0 }}>
                {Object.keys(initialValues).length > 1 ? "Update" : "Create"}{" "}
                Form Element
              </h2>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth mt={6}>
                    <InputLabel id="formElementType-label">
                      Select Type
                    </InputLabel>
                    <Select
                      id="formElementType"
                      onChange={handleChange}
                      name="formElementType"
                      label="Select Type"
                      labelId="formElementType-label"
                      value={
                        values.formElementType?.id || values.formElementType
                      }
                      error={Boolean(
                        touched.formElementType && errors.formElementType
                      )}
                    >
                      {formElementTypes.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {Boolean(
                      touched.formElementType && errors.formElementType
                    ) && (
                      <FormHelperText error={true}>
                        {errors.formElementType}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth mt={6}>
                    <InputLabel id="required-label">Required</InputLabel>
                    <Select
                      id="required"
                      label="Required"
                      labelId="required-label"
                      name="required"
                      onChange={handleChange}
                      value={values?.required}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                    {Boolean(touched.required && errors.required) && (
                      <FormHelperText error={true}>
                        {errors.required}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <TextField
                mt={6}
                fullWidth
                label="Identifier"
                onChange={handleChange}
                id="formElementIdentifier"
                name="formElementIdentifier"
                defaultValue={values?.formElementIdentifier}
                helperText={
                  touched.formElementIdentifier && errors.formElementIdentifier
                }
                error={Boolean(
                  touched.formElementIdentifier && errors.formElementIdentifier
                )}
              />

              <TextField
                mt={6}
                fullWidth
                id="question"
                name="question"
                label="Question"
                onChange={handleChange}
                defaultValue={values?.question}
                helperText={touched.question && errors.question}
                error={Boolean(touched.question && errors.question)}
              />

              <TextField
                mt={6}
                fullWidth
                id="placeholder"
                label="Placeholder"
                onChange={handleChange}
                defaultValue={values?.placeholder}
                helperText={touched.placeholder && errors.placeholder}
                error={Boolean(touched.placeholder && errors.placeholder)}
              />

              <TextField
                mt={6}
                fullWidth
                id="description"
                label="Description"
                onChange={handleChange}
                defaultValue={values?.description}
                helperText={touched.description && errors.description}
                error={Boolean(touched.description && errors.description)}
              />

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={handleChildChange}
                onInputChange={handleInputChange}
                options={childSections}
                fullWidth
                value={
                  selectedChildSections &&
                  Object.keys(selectedChildSections).length > 0
                    ? selectedChildSections
                    : null
                }
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} mt={6} label="Select Section" />
                )}
              />

              <FormControl fullWidth mt={6}>
                <InputLabel id="sectionElementEnd-label">
                  Is Section end here
                </InputLabel>
                <Select
                  id="sectionElementEnd"
                  name="sectionElementEnd"
                  label="Is Section end here"
                  labelId="sectionElementEnd-label"
                  onChange={handleChange}
                  value={values?.sectionElementEnd}
                  disabled={
                    !(
                      selectedChildSections &&
                      Object.keys(selectedChildSections).length > 0
                    )
                  }
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                {Boolean(
                  touched.sectionElementEnd && errors.sectionElementEnd
                ) && (
                  <FormHelperText error={true}>
                    {errors.sectionElementEnd}
                  </FormHelperText>
                )}
              </FormControl>

              <Grid container spacing={4} mt={2}>
                <Grid item xs={12}>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="options-content"
                      id="options-header"
                    >
                      <InputLabel id="status-label">Options</InputLabel>
                    </AccordionSummary>
                    <AccordionDetails>
                      {options.map((opt, index) => (
                        <Grid container spacing={4} key={index}>
                          <Grid item xs={10}>
                            <TextField
                              mt={6}
                              fullWidth
                              name={opt}
                              value={opt}
                              id={opt}
                              label={`Option ${index + 1}`}
                              onChange={(e) => handleOptionChange(e, index)}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            mt={6}
                            sx={{
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => handleRemoveOptions(index)}
                            >
                              Remove
                            </Button>
                          </Grid>
                        </Grid>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} mt={2}>
                  <Button variant="contained" onClick={handleAddOptions}>
                    {options && options.length > 0
                      ? "Add Another Option"
                      : "Add Option"}
                  </Button>
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth mt={6}>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      id="active"
                      name="active"
                      label="Status"
                      labelId="status-label"
                      onChange={handleChange}
                      value={values?.active}
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Inactive</MenuItem>
                    </Select>
                    {Boolean(touched.active && errors.active) && (
                      <FormHelperText error={true}>
                        {errors.active}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth mt={6}>
                    <InputLabel id="lastNode-label">Is Last Node</InputLabel>
                    <Select
                      id="lastNode"
                      name="lastNode"
                      label="Is Last Node"
                      labelId="lastNode-label"
                      onChange={handleChange}
                      value={values?.lastNode}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                    {Boolean(touched.lastNode && errors.lastNode) && (
                      <FormHelperText error={true}>
                        {errors.lastNode}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <FormControl sx={{ mt: 6 }} fullWidth>
                <InputLabel id="validation-type-label">
                  Validation Type
                </InputLabel>
                <Select
                  labelId="validation-type-label"
                  id="validation-type"
                  name="validationType"
                  value={values.validationType}
                  label="Validation Type"
                  onChange={handleChange}
                >
                  <MenuItem value="SERVER_SIDE">Server Side</MenuItem>
                  <MenuItem value="SANITY_CHECK">Sanity Check</MenuItem>
                  <MenuItem value="CLIENT_SIDE">Client Side</MenuItem>
                </Select>
                {Boolean(touched.validationType && errors.validationType) && (
                  <FormHelperText error={true}>
                    {errors.validationType}
                  </FormHelperText>
                )}
              </FormControl>

              {values.validationType === "SANITY_CHECK" && (
                <FormControl
                  sx={{ m: 3 }}
                  fullWidth
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel component="legend">
                    Client-side Validation Properties
                  </FormLabel>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <FormGroup>
                        {getClientsideValidationProps("base", null).map(
                          (prop, index) => (
                            <FormControlLabel
                              key={index}
                              value={prop.type}
                              control={
                                <Checkbox
                                  onChange={handleCheckChange}
                                  checked={currentType.includes(prop.type)}
                                />
                              }
                              label={prop.type}
                            />
                          )
                        )}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={5}>
                      {currentType &&
                        currentType.map((item, i) => (
                          <div key={i}>
                            {getClientsideValidationProps("children", item).map(
                              (prop, index) => (
                                <TextField
                                  key={prop.type}
                                  mt={6}
                                  fullWidth
                                  name={prop.type}
                                  value={getChildrenValue(prop.type)}
                                  id={prop.type}
                                  label={prop.type}
                                  type="number"
                                  onChange={handleValidationChange}
                                />
                              )
                            )}
                          </div>
                        ))}
                    </Grid>
                  </Grid>
                </FormControl>
              )}

              <Grid container justifyContent="end">
                <Grid item>
                  <Button
                    mt={6}
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {Object.keys(initialValues).length > 1
                      ? "Update"
                      : "Create"}{" "}
                    Element
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        )}
      </Formik>
      {/* </Dialog> */}
    </>
    //   )}
    // </Box>
  );
};

export default ElementForm;
