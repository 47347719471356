import styled from "styled-components/macro";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const CloseIcon = styled(IconButton)`
  top:5px;
  right:5px;
  position:absolute;
`;

const ModalCloseIcon = (props) => {
  return (
    <CloseIcon color="error" {...props}>
      <Close />
    </CloseIcon>
  );
};

export default ModalCloseIcon;
