import axios from "axios";
import { FORM_API_BASE_URL } from "../constants";

export const fetchAllSections = (config) => {
  return axios.get(`${FORM_API_BASE_URL}/api/section/all`, config);
};

export const fetchSectionById = (id) => {
  return axios.get(`${FORM_API_BASE_URL}/api/section/get/${id}`);
};

export const createSection = (data) => {
  return axios.post(`${FORM_API_BASE_URL}/api/section/create`, data);
};

export const updateSection = (data) => {
  return axios.put(`${FORM_API_BASE_URL}/api/section/update`, data);
};

export const deleteSectionById = (id, config) => {
  return axios.delete(
    `${FORM_API_BASE_URL}/api/v1/form-element-type/delete/id/${id}`
  );
};
