import { FolderPlus } from "react-feather";

const formsSection = [
  {
    href: "/",
    icon: FolderPlus,
    title: "Form Builder",
    children: [
      {
        href: "/element/type",
        title: "Element Type",
      },
      {
        href: "/sections",
        title: "Sections",
      },
      {
        href: "/",
        title: "Forms",
      },
    ],
  },
];

const navItems = [
  {
    title: "Forms",
    pages: formsSection,
  },
];

export default navItems;
