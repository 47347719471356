import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import ModalCloseIcon from "./ModalCloseIcon";
import {
  Box,
  Button as MuiBtn,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
} from "@mui/material";

const Button = styled(MuiBtn)(spacing);

const EdgeAction = ({ open, onClose, onEdgeDelete, onEdgeEdit }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{ sx: { padding: "30px 15px" } }}
    >
      <ModalCloseIcon onClick={onClose} />
      <DialogTitle sx={{ textAlign: "center", marginBottom: 3, fontSize: 25 }}>
        <strong>Are you sure?</strong>
      </DialogTitle>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Box sx={{ display: "flex" }}>
          {/* <Button variant="contained" color="error" onClick={onEdgeDelete}>Delete</Button>
                    <Divider sx={{margin: "0 10px"}}>
                        <Chip label="OR"/>
                    </Divider> */}
          <Button variant="contained" onClick={onEdgeEdit}>
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EdgeAction;
