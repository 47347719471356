import cogoToast from "cogo-toast";
import { withStyles } from "@mui/styles";
import { Box, spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { useConfirm } from "material-ui-confirm";
import Loader from "../../components/ui/Loader";
import { Delete, Edit } from "@mui/icons-material";
import APIResourceForm from "../../components/form-builder/APIResourceForm";
import {
  updateAPIResource,
  deleteAPIResource,
  fetchFormElementWithAPIResources,
} from "../../api/form-builder";
import {
  IconButton,
  Grid as MuiGrid,
  Typography as MuiTypo,
  Divider as MuiDivider,
} from "@mui/material";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypo)(spacing);

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      paddingTop: "10px",
      paddingBottom: "10px",
      wordBreak: "break-all",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .cell--request-bodies": {
      display: "block",
      lineHeight: "20px",
    },
  },
})(DataGrid);

const APIResourceList = () => {
  const params = useParams();
  const { id } = params;
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(true);
  const [apiResources, setApiResources] = useState([]);
  const [apiResource, setApiResource] = useState(null);
  const [formElement, setFormElement] = useState(null);
  const [apiResourceDialog, setApiResourceDialog] = useState(false);

  const handleAPIResourceEditClick = async (id) => {
    const findAPIResource = await apiResources.find((item) => item.id === id);
    await setApiResource(findAPIResource);
    setApiResourceDialog(true);
  };

  const handleAPIResourceUpdate = (values, { setSubmitting }) => {
    const { headers } = values;
    const data = {
      ...values,
      headers: headers.reduce(
        (i, header) => ({ ...i, [header.key]: header.value }),
        {}
      ),
    };

    updateAPIResource(data)
      .then((res) => {
        if (res.data.success) {
          setSubmitting(false);
          setApiResourceDialog(false);
          setApiResource(null);
          getFormElement();
          cogoToast.success(res.data.message);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        cogoToast.error(error.response.data.message);
      });
  };

  const handleAPIResourceDelete = (id) => {
    confirm({
      title: "Are you sure to delete?",
      dialogProps: { maxWidth: "xs" },
      confirmationText: "Delete",
      confirmationButtonProps: { color: "error" },
    }).then(() => {
      deleteAPIResource(id)
        .then((res) => {
          cogoToast.success(res.data.message);
          getFormElement();
        })
        .catch((error) => {
          cogoToast.error(error.response.data.message);
        });
    });
  };

  const columns = [
    { field: "apiUrl", headerName: "API URL", sortable: false, width: 250 },
    { field: "requestMethod", headerName: "Method", sortable: false, flex: 1 },
    {
      field: "formElementApiDataType",
      headerName: "Type",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        if (params.value === "CONDITIONAL_NODE") return "Conditional";
        if (params.value === "FORM_ELEMENT_API_DATA") return "API Data";
      },
    },
    {
      field: "requestBodies",
      headerName: "Request Bodies",
      width: 300,
      sortable: false,
      cellClassName: "cell--request-bodies",
      renderCell: (params) =>
        params.value.map((i) => (
          <Box lineHeight={2}>
            <span>
              <b>Field:</b> {i.field}, <b>Required:</b>{" "}
              {i.required ? "Yes" : "No"}
            </span>
          </Box>
        )),
    },
    {
      field: "headers",
      headerName: "Headers",
      width: 300,
      sortable: false,
      cellClassName: "cell--request-bodies",
      renderCell: (params) =>
        Object.entries(params.value)
          .map((header) => {
            return {
              key: header[0],
              value: header[1],
            };
          })
          .map((header) => (
            <Box lineHeight={2}>
              <span>
                <b>{header.key}:</b> {header.value}
              </span>
            </Box>
          )),
    },
    {
      field: "active",
      headerName: "Status",
      sortable: false,
      flex: 1,
      renderCell: (params) => (params.value ? "Active" : "Inactive"),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleAPIResourceEditClick(params.id)}
          >
            <Edit />
          </IconButton>
          {/* <IconButton color="error" onClick={() => handleAPIResourceDelete(params.id)}>
                        <Delete/>
                    </IconButton> */}
        </>
      ),
    },
  ];

  const getFormElement = () => {
    setIsLoading(true);
    fetchFormElementWithAPIResources(id)
      .then((res) => {
        if (res.data.success) {
          setFormElement(res.data.payload);
          const elemDataSet = res?.data?.payload?.formElementApiDataSet;
          const elemAPIResources = elemDataSet
            .filter((data) => !data?.deleted)
            .map((item) => item?.apiResource)
            .filter((resource) => !resource?.deleted);
          setApiResources(elemAPIResources);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getFormElement();
  }, [id]);

  return (
    <div>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            API Resources
          </Typography>

          <Typography mt={1} variant="h6" color="#333">
            <b>Element:</b> {formElement?.question}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={5} />

      {isLoading && <Loader />}

      {!isLoading && (
        <div style={{ height: "700px", width: "100%" }}>
          <StyledDataGrid
            align="center"
            columns={columns}
            rows={apiResources}
            getRowHeight={() => "auto"}
          />
        </div>
      )}

      {apiResourceDialog && (
        <APIResourceForm
          open={apiResourceDialog}
          initialValues={apiResource}
          onSubmit={handleAPIResourceUpdate}
          onClose={() => setApiResourceDialog(false)}
        />
      )}
    </div>
  );
};

export default APIResourceList;
