import * as Yup from "yup";
import { Formik } from "formik";
import { spacing } from "@mui/system";
import Select from "@mui/material/Select";
import styled from "styled-components/macro";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import { Autocomplete, Dialog, FormHelperText, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import useMediaQuery from "@mui/material/useMediaQuery";
import ModalCloseIcon from "./ModalCloseIcon";

const defaultValue = {
  name: "",
  shortDescription: "",
  precedence: 0,
  active: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  shortDescription: Yup.string().notRequired(),
  precedence: Yup.number().notRequired().nullable(),
  active: Yup.boolean().required(),
});

const Button = styled(LoadingButton)(spacing);

const SectionForm = ({
  initialValue,
  parentSections,
  selectedParentSections,
  open,
  handleParentChange,
  handleInputChange,
  onCloseHandler,
  onFormSubmitHandler,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      fullWidth={true}
      fullScreen={fullScreen}
      onClose={onCloseHandler}
    >
      <ModalCloseIcon onClick={onCloseHandler} />
      <div style={{ padding: "30px" }}>
        <h2 style={{ marginTop: 0 }}>
          {!initialValue ? "Create New Section" : "Update Section"}
        </h2>
        <Formik
          onSubmit={onFormSubmitHandler}
          validationSchema={validationSchema}
          initialValues={initialValue ?? defaultValue}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            isSubmitting,
            touched,
            errors,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                id="name"
                fullWidth
                label="Name"
                onChange={handleChange}
                defaultValue={values?.name}
                sx={{ marginBottom: 7, marginTop: 2 }}
                helperText={touched.name && errors.name}
                error={Boolean(touched.name && errors.name)}
              />

              <TextField
                id="shortDescription"
                fullWidth
                label="Short Description"
                onChange={handleChange}
                defaultValue={values?.shortDescription}
                sx={{ marginBottom: 7, marginTop: 2 }}
                placeholder="Short Description"
                helperText={touched.shortDescription && errors.shortDescription}
                error={Boolean(
                  touched.shortDescription && errors.shortDescription
                )}
              />

              <TextField
                id="precedence"
                fullWidth
                type={"number"}
                label="Precedence"
                onChange={handleChange}
                defaultValue={values?.precedence}
                sx={{ marginBottom: 7, marginTop: 2 }}
                helperText={touched.precedence && errors.precedence}
                error={Boolean(touched.precedence && errors.precedence)}
              />

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={handleParentChange}
                onInputChange={handleInputChange}
                options={parentSections}
                fullWidth
                value={
                  selectedParentSections &&
                  Object.keys(selectedParentSections).length > 0
                    ? selectedParentSections
                    : null
                }
                sx={{ marginBottom: 7, marginTop: 2 }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Select Parent" />
                )}
              />

              <FormControl fullWidth>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  id="active"
                  label="Status"
                  labelId="status-label"
                  name="active"
                  onChange={handleChange}
                  value={values?.active}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>

                {Boolean(errors.active && touched.active) && (
                  <FormHelperText error={true}>{errors.active}</FormHelperText>
                )}
              </FormControl>

              <Button
                mt={5}
                type="submit"
                size="large"
                variant="contained"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {initialValue ? "Edit" : "Create"}
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default SectionForm;
