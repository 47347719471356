import * as Yup from "yup";
import { Formik } from "formik";
import { spacing } from "@mui/system";
import Select from "@mui/material/Select";
import styled from "styled-components/macro";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import { Dialog, FormHelperText, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import useMediaQuery from "@mui/material/useMediaQuery";
import ModalCloseIcon from "./ModalCloseIcon";

const defaultValue = {
  name: "",
  type: "",
  active: ""
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  type: Yup.string().required().matches(/^([a-z])[\da-z_-]+$/g, "Only lowercase without space allowed!"),
  active: Yup.boolean().required()
});

const Button = styled(LoadingButton)(spacing);

const ElementTypeForm = ({ initialValue, open, onCloseHandler, onFormSubmitHandler }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      fullWidth={true}
      fullScreen={fullScreen}
      onClose={onCloseHandler}
    >
      <ModalCloseIcon onClick={onCloseHandler} />
      <div style={{ padding: "30px" }}>
        <h2 style={{ marginTop: 0 }}>
          {!initialValue ? "Create New Form Element Type" : "Update Form Element Type"}
        </h2>
        <Formik
          onSubmit={onFormSubmitHandler}
          validationSchema={validationSchema}
          initialValues={initialValue ?? defaultValue}
        >
          {({
              values,
              handleSubmit,
              handleChange,
              isSubmitting,
              touched,
              errors
            }) => (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                id="name"
                fullWidth
                label="Name"
                onChange={handleChange}
                defaultValue={values?.name}
                sx={{ marginBottom: 7, marginTop: 2 }}
                helperText={touched.name && errors.name}
                error={Boolean(touched.name && errors.name)}
              />

              <TextField
                id="type"
                fullWidth
                label="Type/Tag"
                onChange={handleChange}
                defaultValue={values?.type}
                disabled={Boolean(initialValue)}
                sx={{ marginBottom: 7, marginTop: 2 }}
                placeholder="Ex:text/email/textbox/number/radio"
                helperText={touched.type && errors.type}
                error={Boolean(touched.type && errors.type)}
              />

              <FormControl fullWidth>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  id="active"
                  label="Status"
                  labelId="status-label"
                  name="active"
                  onChange={handleChange}
                  value={values?.active}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>

                {Boolean(errors.active && touched.active) && (
                  <FormHelperText error={true}>{errors.active}</FormHelperText>
                )}
              </FormControl>

              <Button
                mt={5}
                type="submit"
                size="large"
                variant="contained"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {initialValue ? "Edit" : "Create"}
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default ElementTypeForm;
