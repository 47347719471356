import { maxWidth, spacing } from "@mui/system";
import styled from "styled-components/macro";
import { useConfirm } from "material-ui-confirm";
import Loader from "../../components/ui/Loader";
import { NavLink, useParams } from "react-router-dom";
import React, { useCallback, useState, useEffect } from "react";
import FormBuilderPageHeader from "./FormBuilderPageHeader";
import EdgeAction from "../../components/form-builder/EdgeAction";
import ElementForm from "../../components/form-builder/ElementForm";
import ElementLabel from "../../components/form-builder/ElementLabel";
import APIProtocolForm from "../../components/form-builder/APIProtocolForm";
import APIResourceForm from "../../components/form-builder/APIResourceForm";
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
} from "react-flow-renderer";
import {
  fetchFormById,
  createAPIProtocol,
  createFormElement,
  updateFormElement,
  updateAPIProtocol,
  deleteFormElement,
  createAPIResource,
  createElementAPIData,
  fetchFormElementById,
  deleteAPIProtocolById,
  fetchAPIProtocolsByFormId,
  createCondition,
  updateCondition,
  deleteConditionById,
} from "../../api/form-builder";
import {
  Grid,
  Link,
  Typography,
  Breadcrumbs,
  Button as MuiButton,
  Divider as MuiDivider,
  Drawer,
} from "@mui/material";
import { fetchAllSections } from "../../api/section";

const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);
const props = [
  // { type: "not_null", value: "", parent: null },
  { type: "number", value: "", parent: null },
  { type: "string", value: "", parent: null },
  { type: "email", value: "", parent: null },
  { type: "name", value: "", parent: null },
  { type: "min_value", value: "", parent: "number" },
  { type: "max_value", value: "", parent: "number" },
  { type: "min_length", value: "", parent: "string" },
  { type: "max_length", value: "", parent: "string" },
];

const PageFormBuilder = () => {
  const params = useParams();
  const { id } = params;
  const confirm = useConfirm();
  const [formName, setFormName] = useState("");
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [elements, setElements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiProtocol, setApiProtocol] = useState(null);
  const [formElement, setFormElement] = useState(null);
  const [formElementId, setFromElementId] = useState(null);
  const [edgeActionDialog, setEdgeActionDialog] = useState(false);
  const [formElementDialog, setFormElementDialog] = useState(false);
  const [apiResourceDialog, setApiResourceDialog] = useState(false);
  const [apiProtocolDialog, setApiProtocolDialog] = useState(false);
  const [right, setRight] = useState(false);
  const [currentType, setCurrentType] = useState([]);
  const [clientSideValidation, setClientSideValidation] = useState([]);
  const [minValue, setMinValue] = useState("");
  const [minLength, setMinLength] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [options, setOptions] = useState([]);
  const [childSections, setChildSections] = useState([]);
  const [selectedChildSections, setSelectedChildSections] = useState([]);
  const [inputValueChildSection, setInputValueChildSection] = useState("");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setRight(open);
  };

  const handleChildChange = (event, newValue) => {
    if (newValue) {
      setSelectedChildSections(newValue);
    } else {
      setSelectedChildSections([]);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValueChildSection(newInputValue);
  };

  const getAllChildSections = async () => {
    const config = {
      params: {
        asc_or_desc: "ASCENDING",
        sort_by: "name",
        child: true,
        page_size: 100,
        name: inputValueChildSection.length > 0 ? inputValueChildSection : null,
      },
    };
    fetchAllSections(config)
      .then((res) => {
        const sectionResp = res.data.payload;
        setChildSections(sectionResp.content);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getSelectedSection = (sectionId) => {
    let sectionIndex = childSections.findIndex((sec) => sec.id == sectionId);
    if (sectionIndex !== -1) {
      return childSections[sectionIndex];
    } else {
      return {};
    }
  };

  useEffect(() => {
    getAllChildSections();
  }, [inputValueChildSection]);

  useEffect(() => {
    if (formElement && formElement.section && formElement.section.id) {
      setSelectedChildSections(getSelectedSection(formElement.section.id));
    }
  }, [formElement]);

  const onFormElementDialogOpen = () => {
    setFormElementDialog(true);
    setCurrentType([]);
    setClientSideValidation([]);
    setMinValue("");
    setMaxValue("");
    setMinLength("");
    setMaxLength("");
    setOptions([]);
    setRight(true);
  };

  const onFormElementDialogClose = () => {
    setFormElement(null);
    setFormElementDialog(false);
    setRight(false);
    setSelectedChildSections({});
    setInputValueChildSection("");
  };

  const onEdgeActionDialogClose = () => {
    setApiProtocol(null);
    setEdgeActionDialog(false);
  };

  const onAPIProtocolClose = () => {
    if (apiProtocol?.isUpdate) {
      setApiProtocol(null);
      setApiProtocolDialog(false);
    } else {
      confirm({
        title: "You can't close without configure API Protocol",
        dialogProps: { maxWidth: "xs" },
        cancellationButtonProps: { sx: { display: "none" } },
      });
    }
  };

  const onClickElementConfigButton = (id) => {
    setApiResourceDialog(true);
    setFromElementId(id);
  };

  const onClickElementEditButton = (id) => {
    fetchFormElementById(id)
      .then((res) => {
        if (res.data.success) {
          setFormElement(res.data.payload);

          if (res.data.payload.clientSideValidation) {
            let convertedSideVal = JSON.parse(
              res.data.payload.clientSideValidation
            );
            let types = [];
            convertedSideVal.map((item) => {
              types.push(item.type);
              setChildValues(item.type, item.value);
            });
            setClientSideValidation(convertedSideVal);
            setCurrentType(types);
          } else {
            setClientSideValidation([]);
            setCurrentType([]);
          }
          setFormElementDialog(true);
          if (res.data.payload.optionValues) {
            setOptions(res.data.payload.optionValues);
          }
          setRight(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const onElementCreateUpdate = (values, { setSubmitting }) => {
    const data = {
      formId: parseInt(id),
      active: values.active,
      required: values.required,
      question: values.question,
      description: values.description,
      sectionElementEnd: values.sectionElementEnd,
      placeholder: values.placeholder,
      formElementTypeId: values.formElementType?.id || values.formElementType,
      formElementIdentifier: values.formElementIdentifier,
      sectionId: selectedChildSections?.id || null,
      positionY: formElement ? formElement.positionY : 20,
      positionX: formElement ? formElement.positionX : -200,
      options: options.filter((opt) => opt.length > 0),
      clientSideValidation: JSON.stringify(clientSideValidation),
      serverSideValidation:
        values.validationType === "SERVER_SIDE" ? true : false,
      // serverSideValidation: clientSideValidation.length > 0 ? false : true,
      validationType: values.validationType,
      lastNode: values.lastNode,
    };

    if (!formElement) {
      createFormElement(data).then((res) => {
        if (res.data.success) {
          setSubmitting(false);
          setFormElement(null);
          setFormElementDialog(false);
          setInputValueChildSection("");
          setRight(false);
          setFromElementId(res.data.payload.id);
          setApiResourceDialog(true);
        }
      });
    } else {
      updateFormElement({
        formElementId: formElement.id,
        ...data,
      }).then((res) => {
        if (res.data.success) {
          getFormElements();
          setSubmitting(false);
          setFormElement(null);
          setFormElementDialog(false);
          setSelectedChildSections({});
          setInputValueChildSection("");
          setRight(false);
        }
      });
    }
  };

  const onAPIProtocolCreateUpdate = (values, { setSubmitting }) => {
    let statusCode = values.statusCode;
    //if condition available
    if (apiProtocol?.sourceElement?.validationType === "CLIENT_SIDE") {
      statusCode = `${values.expectedValue}_${values.condition}_${apiProtocol?.sourceElement?.formElementIdentifier}`;
      const data = {
        condition: values.condition,
        expectedValue: values.expectedValue,
        formId: parseInt(id),
        sourceFormElementId: apiProtocol?.sourceFormElementId,
        targetFormElementId: apiProtocol?.targetFormElementId,
      };
      if (apiProtocol.conditionId) {
        data.id = apiProtocol.conditionId;
        updateCondition(data)
          .then((res) => {
            // console.log("condition updated");
            getFormElements();
          })
          .catch((err) => console.log("condition update failed"));
      } else {
        createCondition(data)
          .then((res) => {
            // console.log("condition created", res);
            getFormElements();
          })
          .catch((err) => console.log("condition create failed"));
      }
    }
    if (apiProtocol?.isUpdate) {
      updateAPIProtocol({
        ...apiProtocol,
        statusCode: statusCode,
      })
        .then((res) => {
          if (res.data.success) {
            setApiProtocol(null);
            setApiProtocolDialog(false);
            setEdgeActionDialog(false);
            getAPIProtocols();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      createAPIProtocol({
        ...apiProtocol,
        statusCode: statusCode,
      })
        .then((res) => {
          if (res.data.success) {
            setSubmitting(false);
            getAPIProtocols();
            setApiProtocolDialog(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
          setApiProtocolDialog(false);
        });
    }
  };

  const onAPIResourceDataCreate = (values, { setSubmitting }) => {
    const { headers } = values;
    const data = {
      ...values,
      headers: headers.reduce(
        (i, header) => ({ ...i, [header.key]: header.value }),
        {}
      ),
    };

    createAPIResource(data).then((res) => {
      if (res.data.success) {
        createElementAPIData({
          active: true,
          apiResourceId: res.data.payload.id,
          formElementApiDataType: values.formElementApiDataType,
          formElementId: formElementId,
        }).then((res) => {
          if (res.data.success) {
            setSubmitting(false);
            setFromElementId(null);
            setApiResourceDialog(false);
            getFormElements();
          }
        });
      }
    });
  };

  const onNodeDragStop = (node) => {
    const draggableElm = elements.find((elm) => elm.id === parseInt(node.id));

    const data = {
      formId: id,
      positionY: node.position.y,
      positionX: node.position.x,
      active: draggableElm.active,
      question: draggableElm.question,
      description: draggableElm.description,
      sectionElementEnd: draggableElm.sectionElementEnd,
      required: draggableElm.required,
      sectionId: draggableElm.section?.id,
      formElementId: parseInt(node.id),
      placeholder: draggableElm.placeholder,
      errorMessage: draggableElm.errorMessage,
      successMessage: draggableElm.successMessage,
      formElementTypeId: draggableElm.formElementType.id,
      formElementIdentifier: draggableElm.formElementIdentifier,
      options: draggableElm.options,
      clientSideValidation: draggableElm.clientSideValidation,
      serverSideValidation: draggableElm.serverSideValidation,
      validationType: draggableElm.validationType,
      lastNode: draggableElm.lastNode,
    };

    updateFormElement(data)
      .then((res) => {
        console.log("Successfully updated node!", res.data);
      })
      .catch((err) => console.log(err));
  };

  const onNodeDelete = (id) => {
    confirm({
      title: "Are you sure to delete?",
      confirmationText: "Delete",
      dialogProps: { maxWidth: "xs" },
    }).then(() => {
      deleteFormElement(parseInt(id))
        .then((res) => {
          if (res.data.success) {
            getFormElements();
          }
        })
        .catch((error) => console.log(error));
    });
  };

  const onEdgeClick = (edge) => {
    const findEdge = edges.find((item) => item.id === edge.id);
    const sourceElement = elements.find(
      (node) => node.id === findEdge.sourceFormElementId
    );
    const conditionObject = sourceElement.conditionsSet?.find(
      (condition) => condition.targetNodeId === findEdge.targetFormElementId
    );
    setApiProtocol({
      ...findEdge,
      formId: parseInt(id),
      isUpdate: true,
      sourceElement: sourceElement,
      conditionId: conditionObject?.id,
      condition: conditionObject?.condition,
      expectedValue: conditionObject?.expectedValue,
    });
    setEdgeActionDialog(true);
  };

  const onEdgeDelete = () => {
    setEdgeActionDialog(false);
    confirm({
      title: "Are you sure want to Delete?",
      dialogProps: { maxWidth: "xs" },
      confirmationText: "Confirm",
    })
      .then(() => {
        deleteAPIProtocolById(parseInt(apiProtocol.id))
          .then((res) => {
            if (res.data.success) {
              if (apiProtocol.conditionId) {
                deleteConditionById(apiProtocol.conditionId)
                  .then((res) => {
                    // console.log("condition deleted");
                    getFormElements();
                  })
                  .catch((err) => console.log("condition delete failed"));
              }
              getAPIProtocols();
            }
          })
          .catch((error) => {
            setApiProtocol(null);
            console.log(error);
          });
      })
      .catch(() => {
        setApiProtocol(null);
      });
  };

  const onEdgeEdit = () => {
    setApiProtocolDialog(true);
    setEdgeActionDialog(false);
    setRight(false);
    setInputValueChildSection("");
  };

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  const onConnect = useCallback(
    (connection) => {
      setApiProtocol({
        active: true,
        statusCode: "",
        formId: parseInt(id),
        sourceFormElementId: connection.source,
        targetFormElementId: connection.target,
        sourceElement: elements.find(
          (element) => element.id === parseInt(connection.source)
        ),
        condition: "",
        expectedValue: "",
      });
      setApiProtocolDialog(true);
      setEdges((eds) => addEdge(connection, eds));
    },
    [id, setEdges, elements]
  );

  const getFormElements = () => {
    setIsLoading(true);
    fetchFormById(id).then((res) => {
      if (res.data.success) {
        const formElements = res.data.payload.formElementDtoList;
        const name = res.data.payload.name;
        setElements(formElements);
        setFormName(name);
        const nonDeletedElements = formElements.filter((elm) => !elm.deleted);
        const formNodes = nonDeletedElements.map((elem, index) => {
          return {
            id: elem.id.toString(),
            type: index === 0 ? "input" : "default",
            position: { x: elem.positionX, y: elem.positionY },
            data: {
              label: (
                <ElementLabel
                  elem={elem}
                  onElementDelete={onNodeDelete}
                  onElementEdit={onClickElementEditButton}
                  onElementConfig={onClickElementConfigButton}
                />
              ),
            },
          };
        });
        setNodes([...formNodes]);
        setIsLoading(false);
      }
    });
  };

  const getAPIProtocols = () => {
    setIsLoading(true);
    fetchAPIProtocolsByFormId(id)
      .then((res) => {
        if (res.data.success) {
          const data = res.data.payload;
          const protocols = data.filter((protocol) => !protocol.deleted);
          const flowEdges = protocols.map((item) => {
            const { sourceFormElementId, targetFormElementId, statusCode } =
              item;
            return {
              ...item,
              id: item.id.toString(),
              source: sourceFormElementId.toString(),
              target: targetFormElementId.toString(),
              label: statusCode,
              type: "smoothstep",
              labelStyle: {
                fontSize: "8px",
              },
              labelBgStyle: {
                cursor: "pointer",
              },
              style: { cursor: "pointer" },
            };
          });

          setEdges(flowEdges);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getClientsideValidationProps = (filter, prop) => {
    if (filter === "base") {
      return props.filter((item) => item.parent === null);
    } else if (filter === "children") {
      return props.filter((item) => item.parent === prop);
    }
    return props;
  };

  const handleCheckChange = (e) => {
    let { value, checked } = e.target;
    let validations = clientSideValidation;
    if (checked) {
      setCurrentType([value]);
      let findIndex = props.findIndex((item) => item.type === value);
      if (findIndex !== -1) {
        validations.push(props[findIndex]);
        setClientSideValidation(validations);
      }
    } else {
      let types = currentType.filter((item) => item !== value);
      setCurrentType(types);
      let clientSide = validations.filter(
        (item) => item.type !== value && item.parent !== value
      );
      setClientSideValidation(clientSide);
    }
  };

  const handleValidationChange = (e) => {
    let { name, value } = e.target;
    let validations = clientSideValidation;

    let clientSideIndex = validations.findIndex((item) => item.type === name);
    if (clientSideIndex !== -1) {
      if (value.trim()) {
        validations[clientSideIndex].value = value;
      } else {
        validations.splice(clientSideIndex, 1);
      }
    } else {
      let findIndex = props.findIndex((item) => item.type === name);
      if (findIndex !== -1) {
        props[findIndex].value = value;
        validations.push(props[findIndex]);
      }
    }
    setChildValues(name, value);

    setClientSideValidation(validations);
  };

  const setChildValues = (name, value) => {
    switch (name) {
      case "min_value":
        setMinValue(value);
        break;
      case "max_value":
        setMaxValue(value);
        break;
      case "min_length":
        setMinLength(value);
        break;
      case "max_length":
        setMaxLength(value);
        break;
      default:
        break;
    }
  };

  const getChildrenValue = (name) => {
    switch (name) {
      case "min_value":
        return minValue;
      case "max_value":
        return maxValue;
      case "min_length":
        return minLength;
      case "max_length":
        return maxLength;
      default:
        break;
    }
  };

  const handleAddOptions = () => {
    setOptions([...options, ""]);
  };

  const handleRemoveOptions = (index) => {
    let option = [...options];
    option.splice(index, 1);
    setOptions(option);
  };

  const handleOptionChange = (e, index) => {
    let { value } = e.target;
    let option = [...options];
    option[index] = value;
    setOptions(option);
  };

  useEffect(() => {
    getFormElements();
    getAPIProtocols();
  }, []);

  return (
    <>
      <FormBuilderPageHeader
        onFormElementDialogOpen={onFormElementDialogOpen}
        title={formName}
      />
      {/* <Divider my={5} /> */}

      <div>
        {
          <React.Fragment>
            <Drawer
              anchor="right"
              open={right}
              onClose={onFormElementDialogClose}
              sx={{
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: "50%" },
              }}
            >
              <ElementForm
                initialValues={{
                  ...formElement,
                  formElementType: formElement?.formElementType?.id || "",
                  validationType: formElement?.validationType
                    ? formElement.validationType
                    : "",
                }}
                childSections={childSections}
                selectedChildSections={selectedChildSections}
                handleChildChange={handleChildChange}
                handleInputChange={handleInputChange}
                currentType={currentType}
                options={options}
                getChildrenValue={getChildrenValue}
                handleOptionChange={handleOptionChange}
                getClientsideValidationProps={getClientsideValidationProps}
                handleCheckChange={handleCheckChange}
                onSubmit={onElementCreateUpdate}
                onClose={onFormElementDialogClose}
                handleValidationChange={handleValidationChange}
                handleAddOptions={handleAddOptions}
                handleRemoveOptions={handleRemoveOptions}
              />
            </Drawer>
          </React.Fragment>
        }
      </div>

      {isLoading && <Loader />}

      {!isLoading && (
        <div>
          {nodes.length === 0 ? (
            <Typography component="h5" variant="h5" align="center">
              No elements!
            </Typography>
          ) : (
            <div style={{ height: "700px" }}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                fitView={true}
                onConnect={onConnect}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onEdgeClick={(event, edge) => onEdgeClick(edge)}
                onNodeDragStop={(_, node) => onNodeDragStop(node)}
              >
                <Controls />
              </ReactFlow>
            </div>
          )}
          {/* 
                    // {formElementDialog && ( */}
          {/* <ElementForm */}
          {/* //         open={formElementDialog} */}
          {/* //         initialValues={formElement} */}
          {/* //         onSubmit={onElementCreateUpdate} */}
          {/* //         onClose={onFormElementDialogClose} */}
          {/* //     /> */}
          {/* // )} */}

          {apiProtocolDialog && (
            <APIProtocolForm
              open={apiProtocolDialog}
              initialValues={apiProtocol}
              onClose={onAPIProtocolClose}
              onSubmit={onAPIProtocolCreateUpdate}
            />
          )}

          {apiResourceDialog && (
            <APIResourceForm
              open={apiResourceDialog}
              onSubmit={onAPIResourceDataCreate}
              onClose={() => {
                setApiResourceDialog(false);
                getFormElements();
              }}
            />
          )}

          {edgeActionDialog && (
            <EdgeAction
              open={edgeActionDialog}
              onEdgeEdit={onEdgeEdit}
              onEdgeDelete={onEdgeDelete}
              onClose={onEdgeActionDialogClose}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PageFormBuilder;
