import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";

import { spacing } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  CssBaseline,
  Paper as MuiPaper,
  ListItemButton,
  Box as MuiBox,
  Grid,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
// import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import GlobalStyle from "../components/GlobalStyle";
import dashboardItems from "../components/sidebar/dashboardItems";
import { ReactComponent as Logo } from "../vendor/logo.svg";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;
const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
`;
const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {/* <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={dashboardItems}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
          />
        </Box>
      </Drawer> */}
      <AppContent>
        <Brand component={NavLink} to="/">
          <Box ml={1}>
            <img
              src="/static/img/brands/logo_light.svg"
              height={"100px"}
              width={"230px"}
              alt="logo"
            />
          </Box>
        </Brand>
        {/*<Navbar onDrawerToggle={handleDrawerToggle} />*/}

        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} md={8} lg={10}>
            <MainContent p={isLgUp ? 12 : 5}>
              {children}
              <Outlet />
            </MainContent>
          </Grid>
        </Grid>

        <Footer />
      </AppContent>
    </Root>
  );
};

export default Dashboard;
