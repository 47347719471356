// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
//import AuthGuard from "./components/guards/AuthGuard";

// Auth Components
//import ProtectedPage from "./pages/protected/ProtectedPage";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import SignUp from "./pages/auth/SignUp";
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";

// From Components
import FormList from "./pages/form-builder/FormList";
import PageFormBuilder from "./pages/form-builder/FormBuilder";
import FormElementType from "./pages/form-builder/FormElementType";
import APIResourceList from "./pages/form-builder/APIResourceList";
import SectionList from "./pages/sections/SectionList";

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <FormList />,
      },
      {
        path: "build/:id",
        element: <PageFormBuilder />,
      },
      // {
      //   path: "element/type",
      //   element: <FormElementType />,
      // },
      // {
      //   path: "element/api-resource/:id",
      //   element: <APIResourceList />,
      // },
      // {
      //   path: "sections",
      //   element: <SectionList />,
      // },
    ],
  },
  // {
  //   path: "auth",
  //   element: <AuthLayout />,
  //   children: [
  //     {
  //       path: "sign-in",
  //       element: <SignIn />,
  //     },
  //     {
  //       path: "sign-up",
  //       element: <SignUp />,
  //     },
  //     {
  //       path: "reset-password",
  //       element: <ResetPassword />,
  //     },
  //     {
  //       path: "404",
  //       element: <Page404 />,
  //     },
  //     {
  //       path: "500",
  //       element: <Page500 />,
  //     },
  //   ],
  // },

  // {
  //   path: "*",
  //   element: <AuthLayout />,
  //   children: [
  //     {
  //       path: "*",
  //       element: <Page404 />,
  //     },
  //   ],
  // },
];

export default routes;
