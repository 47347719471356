import { spacing } from "@mui/system";
import { Add } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import {
  Breadcrumbs,
  Button as MuiButton,
  Grid,
  Link,
  Typography,
} from "@mui/material";

const Button = styled(MuiButton)(spacing);

const FormBuilderPageHeader = ({ onFormElementDialogOpen, title }) => {
  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
        <Typography variant="h3" gutterBottom display="inline">
          {title}
        </Typography>

        {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Forms
          </Link>
          <Typography>Builder</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={onFormElementDialogOpen}
          >
            <Add />
            Add Element
          </Button>
        </div> */}
      </Grid>
    </Grid>
  );
};

export default FormBuilderPageHeader;
