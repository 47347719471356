import * as Yup from "yup";
import { Formik } from "formik";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import ModalCloseIcon from "./ModalCloseIcon";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Dialog,
  TextField as MuiTextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const Button = styled(LoadingButton)(spacing);
const TextField = styled(MuiTextField)(spacing);

const validationSchema = (props) => {
  const isConditional = props.sourceElement?.validationType === "CLIENT_SIDE";
  if (isConditional) {
    return Yup.object().shape({
      formId: Yup.number().required(),
      sourceFormElementId: Yup.number().required(),
      targetFormElementId: Yup.number().required(),
      statusCode: Yup.string().notRequired(),
      condition: Yup.string().required(),
      expectedValue: Yup.string().required(),
    });
  } else {
    return Yup.object().shape({
      formId: Yup.number().required(),
      sourceFormElementId: Yup.number().required(),
      targetFormElementId: Yup.number().required(),
      statusCode: Yup.string().required(),
      condition: Yup.string().notRequired(),
      expectedValue: Yup.string().notRequired(),
    });
  }
};

const APIProtocolForm = ({ initialValues, open, onClose, onSubmit }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <ModalCloseIcon onClick={onClose} />
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={() => validationSchema(initialValues)}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <form noValidate onSubmit={handleSubmit} style={{ padding: 30 }}>
            <h2 style={{ margin: 0 }}>Configure API Protocol</h2>

            <TextField
              mt={7}
              disabled
              fullWidth
              id="formId"
              label="Form ID"
              defaultValue={values?.formId}
              helperText={touched.formId && errors.formId}
              error={Boolean(touched.formId && errors.formId)}
            />

            <TextField
              mt={6}
              disabled
              fullWidth
              id="sourceFormElementId"
              label="Source Element ID"
              defaultValue={values?.sourceFormElementId}
              helperText={
                touched.sourceFormElementId && errors.sourceFormElementId
              }
              error={Boolean(
                touched.sourceFormElementId && errors.sourceFormElementId
              )}
            />

            <TextField
              mt={6}
              disabled
              fullWidth
              id="targetFormElementId"
              label="Target Element ID"
              defaultValue={values?.targetFormElementId}
              helperText={
                touched.targetFormElementId && errors.targetFormElementId
              }
              error={Boolean(
                touched.targetFormElementId && errors.targetFormElementId
              )}
            />

            {initialValues?.sourceElement?.validationType === "CLIENT_SIDE" ? (
              <div>
                <FormControl sx={{ mt: 6 }} fullWidth>
                  <InputLabel id="condition-label">Condition</InputLabel>
                  <Select
                    labelId="condition-label"
                    id="condition"
                    value={values.condition}
                    label="Condition"
                    name="condition"
                    onChange={handleChange}
                  >
                    <MenuItem value="EQ">Equal</MenuItem>
                    <MenuItem value="NEQ">Not Equal</MenuItem>
                    <MenuItem value="GT">Greater Than</MenuItem>
                    <MenuItem value="LT">Less Than</MenuItem>
                    <MenuItem value="GTE">Greater Than Equal</MenuItem>
                    <MenuItem value="LTE">Less Than Equal</MenuItem>
                  </Select>
                  {Boolean(touched.condition && errors.condition) && (
                    <FormHelperText error={true}>
                      {errors.condition}
                    </FormHelperText>
                  )}
                </FormControl>

                <TextField
                  mt={6}
                  fullWidth
                  id="expectedValue"
                  name="expectedValue"
                  value={values.expectedValue}
                  onChange={handleChange}
                  label="Expected Value"
                  helperText={touched.expectedValue && errors.expectedValue}
                  error={Boolean(touched.expectedValue && errors.expectedValue)}
                />
              </div>
            ) : (
              <TextField
                mt={6}
                fullWidth
                id="statusCode"
                name="statusCode"
                onChange={handleChange}
                defaultValue={values?.statusCode}
                label="Status Code/Validation Code"
                helperText={touched.statusCode && errors.statusCode}
                error={Boolean(touched.statusCode && errors.statusCode)}
              />
            )}

            <Button
              mt={6}
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default APIProtocolForm;
